// src/App.js
import React, { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Navigation from './common/components/Navigation/Navigation';
import Footer from './common/components/Footer/Footer';
import CampaignBanner from './pages/Misc/CampaignBanner';
import { LoadingSpinner } from './common/components/UIElements/loadingAnimations';
import ErrorBoundary from './components/ErrorBoundary';
import { dummyProjects } from './data/projectsData';

const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const NotFound = lazy(() => import('./pages/404/404'));
const Demos = lazy(() => import('./pages/Demos/Demos'));
const Projects = lazy(() => import('./pages/Projects/Projects')); 
const ProjectDetail = lazy(() => import('./pages/Projects/components/ProjectDetail'));

const App = () => {
  return (
    <React.Fragment>
      <ErrorBoundary>
        <Router>
          <CampaignBanner />
          <Navigation />
          <React.Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/demos" element={<Demos />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/:id" element={<ProjectDetail projects={dummyProjects} />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
          </React.Suspense>
          <Footer />
        </Router>
      </ErrorBoundary>
    </React.Fragment>
  );
};

export default App;