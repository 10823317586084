import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner, OverlayTrigger, Tooltip, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useHttpClient from '../../hooks/http-hook';
import './Footer.scss';

const Footer = () => {
  const [visits, setVisits] = useState(0);
  const { isLoading, error, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `https://api.countapi.xyz/hit/itsrakesh.com/${process.env.REACT_APP_COUNTAPI_KEY}`
        );
        setVisits(responseData.value);
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest]);

  return (
    <div id="footer">
      <footer>
        <Row>
          <Col sm>
            {/* Removed "Let's Connect" text and social links */}
            {!isLoading && visits?.length > 0 && (
              <h6>
                Visits:{' '}
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip>Times Viewed</Tooltip>}
                >
                  <span className="visits">
                    {isLoading ? (
                      <Spinner animation="grow" size="sm" />
                    ) : (
                      visits
                    )}
                    {error && <span>{error}</span>}
                  </span>
                </OverlayTrigger>
              </h6>
            )}
          </Col>
        </Row>
        <span className="divider"></span>
        <Row className="extras">
          <Col sm>
            <Stack>
              <a
                href="https://forms.gle/2mtF1kybEQQQVrRdA"
                target="_blank"
                rel="noreferrer noopener"
              >
                Report
              </a>
              <a
                href="https://forms.gle/PbcqrhTEWQtKxE6g7"
                target="_blank"
                rel="noreferrer noopener"
              >
                Feedback
              </a>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Stack>
          </Col>
        </Row>
      </footer>
      <Stack className="copyright" direction="horizontal" gap={5}>
        <p>© 2021 - 2024 [BR6] All rights reserved.</p>
        {/* Removed "Made with 💖 by Me!" */}
        <p className="ms-auto">v1.1.0</p>
      </Stack>
    </div>
  );
};

export default Footer;
