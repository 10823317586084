// src/common/hooks/http-hook.jsx
import { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';

const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  
  const activeHttpRequests = useRef([]);

  const sendRequest = useCallback(async (url, method = 'GET', data = null) => {
    setIsLoading(true);
    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    try {
      const response = await axios({
        method,
        url,
        data,
        signal: httpAbortCtrl.signal,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      activeHttpRequests.current = activeHttpRequests.current.filter(
        reqCtrl => reqCtrl !== httpAbortCtrl
      );

      setIsLoading(false);
      return response.data;
    } catch (err) {
      setError(err.message || 'Something went wrong');
      setShow(true);
      setIsLoading(false);
      throw err;
    }
  }, []);

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
    };
  }, []);

  const clearError = () => {
    setError(null);
    setShow(false);
  };

  return { isLoading, error, sendRequest, show, clearError };
};

export default useHttpClient;