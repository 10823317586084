// src/data/projectsData.js
export const dummyProjects = [
  {
    id: "apt-finder",
    title: "Apt Finder",
    category: "Mobile Development",
    summary: "An AR-powered solution that helps delivery drivers easily locate apartment numbers using their phone camera.",
    image: "/assets/images/apt-finder-cover.jpg",
    tags: ["AR", "Mobile", "React Native"],
    featured: true,
    fullDescription: `Back when I used to deliver for DoorDash during the pandemic, I dreaded apartment orders. It felt like navigating a torturous maze just to deliver a $5 order to a third-floor apartment—and, of course, the apartment number was never the one I needed when I got there.

    That frustration sparked an idea: What if I could simply point my camera at an apartment building (or any large building) and instantly see the address I needed floating in 3D space, like augmented reality? Picture something like Pokémon GO, but instead of catching Pokémon, you're finding addresses effortlessly.
    
    The concept—Apt Finder—would use AR technology to overlay addresses in 3D space, making it simple to find your way in complex locations. While I'm excited about the possibilities, I also know it's an ambitious project that will take significant time and resources to bring to life.
    
    For now, Apt Finder is on the back burner, but it's a dream I'm determined to pursue when the time is right. Until then, I'll keep brainstorming and refining the concept. Who knows? Maybe one day it'll be a game-changer for delivery drivers everywhere.`,
    media: {
      prototype: [
        "/assets/images/apt-finder-proto-1.jpg",
        "/assets/images/apt-finder-proto-2.jpg",
        "/assets/images/apt-finder-proto-3.jpg"
      ],
      video: "/assets/videos/apt-finder-demo.mp4",
      figmaEmbed: "https://embed.figma.com/proto/GG1DAjjK21ZXL4qvAiKhng/Aptfinder-design-V2?node-id=1-4&starting-point-node-id=1%3A4&embed-host=share"
    },
    status: "In Development"
  },
  {
    id: "nuti-poket",
    title: "Nuti-Poket",
    category: "Mobile Development",
    summary: "A smart nutrition tracking app that provides real-time nutritional information for your meals using weight measurements.",
    image: "/assets/images/nuti-poket-cover.jpg",
    tags: ["React Native", "IoT", "Health Tech", "Mobile App"],
    featured: true,
    fullDescription: `Growing up, I loved games like Fallout and Skyrim. Exploring vast worlds and discovering items with detailed stats was endlessly fascinating—like seeing how a piece of food could restore 20 HP or give you 5 stamina at a glance. That concept stuck with me because, in many ways, our world is similar. The food we eat impacts our health, energy, and well-being, but understanding those effects isn't always as straightforward as in a game.

    That got me thinking: How amazing would it be to know exactly what's going into your body from the food you eat—calorie count, sugar, sodium, vitamins, minerals, everything—just by looking at your plate or bowl? Imagine pouring milk and cereal (or any food or drink) and instantly seeing its nutritional breakdown on your phone.

    Enter Nuti-Poket: Nutrition in your pocket! The idea is simple—measure the weight of your meal, calculate its nutritional values using the data from the food's labels, and send that information directly to your phone. It would give you real-time insights into what you're consuming, making healthy choices easier and more informed.

    While a few companies have developed similar products since I first had the idea back in 2016, I believe Nuti-Poket has the potential to surpass them. Most existing solutions are clunky or leave a lot to be desired (pun intended). My version would focus on being intuitive, user-friendly, and accessible to everyone.

    This project is still in the prototype phase, but I'm incredibly excited about its possibilities and can't wait to bring it to life. Stay tuned for updates as I work toward making this a reality!`,
    media: {
      prototype: [
        "/assets/images/nuti-poket-proto-1.jpg",
        "/assets/images/nuti-poket-proto-2.jpg",
        "/assets/images/nuti-poket-proto-3.jpg",
        "/assets/images/nuti-poket-proto-4.jpg"
      ],
      video: "/assets/videos/nuti-poket-demo.mp4",
      figmaEmbed: "https://embed.figma.com/proto/JaTTJJmkKe0OwobnB3Fw10/Untitled?node-id=172-18&starting-point-node-id=251%3A247&embed-host=share"
    },
    status: "Prototype Phase"
  },
  {
    id: "project-beta",
    title: "Project Beta",
    category: "Mobile Development",
    summary: "Cross-platform mobile app delivering seamless experience across devices.",
    image: "/assets/images/project-placeholder-2.jpg",
    tags: ["React Native", "Firebase", "Redux"],
    featured: true,
    fullDescription: `Detailed description of Project Beta will go here.`,
    media: {
      prototype: [
        "/assets/images/project-beta-1.jpg",
        "/assets/images/project-beta-2.jpg"
      ]
    },
    status: "In Progress"
  }
];